(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ReclamationsClientController', ReclamationsClientController);

    ReclamationsClientController.$inject = ['Principal', 'ParseLinks', 'pagingParams', 'paginationConstants', '$localStorage', '$scope', '$stateParams', 'ReclamationConstants', 'Reclamation', 'AlertService', '$state', 'ReclamationUtils', '$sessionStorage', 'recipients'];

    function ReclamationsClientController(Principal, ParseLinks, pagingParams, paginationConstants, $localStorage, $scope, $stateParams, ReclamationConstants, Reclamation, AlertService, $state, ReclamationUtils, $sessionStorage, recipients) {
        var vm = this;

        $scope.$storage = $localStorage;
        if(!$scope.$storage.reclamationsColumns) { //due to $default issue with restoring storage
            $localStorage.reclamationsColumns = { eventDate: true, createdDate: true, comment: true };
        }
        vm.shownColumns = $scope.$storage.reclamationsColumns;
        if (!$scope.$storage.itemsPerPage.reclamations) {
            $localStorage.itemsPerPage.reclamations = paginationConstants.itemsPerPage;
        }
        vm.itemsPerPage = $scope.$storage.itemsPerPage.reclamations;

        vm.loadAll = loadAll;
        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        vm.client = null;
        vm.clientName = null;
        vm.reclamations = [];
        vm.recipientOptions = recipients;

        vm.filterDefaults = {
            internalNumber: null,
            orderNumber: null,
            eventDateFrom: null,
            eventDateTo: null,
            startDateFrom: null,
            startDateTo: null,
            statuses: [],
            recipient: null
        };

        if (!$sessionStorage.filters.reclClient) {
            $sessionStorage.filters.reclClient = vm.filterDefaults;
        }
        restoreFilters($sessionStorage.filters.reclClient);
        vm.searchVisible = true;

        vm.statusOptions = ReclamationConstants.AllStatusOptions;
        vm.itemsPerPageOptions = paginationConstants.itemsPerPageOptions;

        vm.changeItemsPerPage = changeItemsPerPage;
        vm.openCalendar = openCalendar;
        vm.toggleStatusFilter = toggleStatusFilter;
        vm.getStatusKey = getStatusKey;
        vm.clearFilter = clearFilter;

        init();

        function restoreFilters (savedFilters) {
            vm.searchParams = savedFilters;
            vm.searchParams.startDateFrom = savedFilters.startDateFrom ? new Date(vm.searchParams.startDateFrom) : null;
            vm.searchParams.startDateTo = savedFilters.startDateTo ? new Date(vm.searchParams.startDateTo) : null;
            vm.searchParams.eventDateFrom = savedFilters.eventDateFrom ? new Date(vm.searchParams.eventDateFrom) : null;
            vm.searchParams.eventDateTo = savedFilters.eventDateTo ? new Date(vm.searchParams.eventDateTo) : null;
        }

        function clearFilter () {
            vm.searchParams = vm.filterDefaults;
            $sessionStorage.filters.reclClient = vm.filterDefaults;
        }

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.clientName = client.name;
                vm.loadAll();
            });
        }

        function loadAll () {
            Reclamation.find({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, vm.searchParams, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.reclamations = data;
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage () {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function changeItemsPerPage () {
            // 2-way binding doesn't seem to work here for some reason
            $scope.$storage.itemsPerPage.reclamations = vm.itemsPerPage;
            loadAll();
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function toggleStatusFilter (status) {
            if (status) {
                vm.searchParams.statuses = [status];
            } else {
                vm.searchParams.statuses = [];
            }
        }

        function getStatusKey(status, subStatus) {
            return ReclamationUtils.getStatusLabelKey(status, subStatus);
        }
    }
})();