(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('SalesOrderDetailController', SalesOrderDetailController);

    SalesOrderDetailController.$inject = ['order', 'solp', 'typeTranslation', 'SalesOrderStatuses', 'OrderConstants', 'SalesOrder', '$stateParams', 'FileSaver', '$localStorage', '$scope', 'Base64utf8', 'Reports'];

    function SalesOrderDetailController (order, solp, typeTranslation, SalesOrderStatuses, OrderConstants, SalesOrder, $stateParams, FileSaver, $localStorage, $scope, Base64utf8, Reports) {
        var vm = this;

        vm.order = order;
        vm.solp = solp;
        vm.paymentMethods = OrderConstants.PaymentMethods;
        vm.statusValues = SalesOrderStatuses;
        vm.isWMSStatus = isWMSStatus;
        vm.VATRates = OrderConstants.VATRates;
        vm.orderType = typeTranslation;
        vm.exportToExcel = exportToExcel;
        vm.exportToExcelSoLp = exportToExcelSoLp;

        vm.itemsPerPageOptions = [10, 20, 50];
        vm.pageSku = 1;
        vm.itemsPerPageSku = 10;
        vm.pageSoLp = 1;
        vm.itemsPerPageSoLp = 10;

        $scope.$storage = $localStorage;
        if(!$scope.$storage.soDetailColumns) { //due to $default issue with restoring storage
            $localStorage.soDetailColumns = { reservedQuantity: true, pickedQuantity:true, quality: true };
        }
        vm.shownColumns = $scope.$storage.soDetailColumns;

        function isWMSStatus () {
            if (vm.order) {
                return vm.order.status === 2 || vm.order.status === 3 || vm.order.status === 4 || vm.order.status === 5 || vm.order.status === 6;
            }
            return false;
        }

        function exportToExcel () {
            SalesOrder.linesToExcel({
                code: Base64utf8.encodeMod($stateParams.code),
                clientCode: $stateParams.client,
                type: $stateParams.type
            },
            function (data) {
                var filename = (vm.order.code + "_lines.xlsx").replace(/[/\\?%*:|"<>]/g, '_');
                FileSaver.saveAs(data.blob, filename);
            });
        }

        function exportToExcelSoLp () {
            Reports.exportSoLpSelectedToExcel({
                client: $stateParams.client
            },
            vm.solp,
            function (data) {
                var filename = (vm.order.code + "_solp.xlsx").replace(/[/\\?%*:|"<>]/g, '_');
                FileSaver.saveAs(data.blob, filename);
            });
        }
    }
})();