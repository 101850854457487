(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ReclamationsMgmtController', ReclamationsMgmtController);

    ReclamationsMgmtController.$inject = ['Principal', 'ParseLinks', 'pagingParams', 'paginationConstants', '$localStorage', '$scope', '$stateParams', 'ReclamationConstants', 'Reclamation', 'AlertService', '$state', 'FileSaver', 'ReclamationUtils', '$sessionStorage'];

    function ReclamationsMgmtController(Principal, ParseLinks, pagingParams, paginationConstants, $localStorage, $scope, $stateParams, ReclamationConstants, Reclamation, AlertService, $state, FileSaver, ReclamationUtils, $sessionStorage) {
        var vm = this;

        $scope.$storage = $localStorage;
        if (!$scope.$storage.itemsPerPage.reclamations) {
            $localStorage.itemsPerPage.reclamations = paginationConstants.itemsPerPage;
        }
        vm.itemsPerPage = $scope.$storage.itemsPerPage.reclamations;

        vm.loadAll = loadAll;
        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        vm.client = null;
        vm.clientName = null;
        vm.reclamations = [];
        vm.filterDefaults = {
            internalNumber: null,
            orderNumber: null,
            startDateFrom: null,
            startDateTo: null,
            statuses: [],
            statusesNot: ["DRAFT"]
        };
        
        if (!$sessionStorage.filters.reclMgmt) {
            $sessionStorage.filters.reclMgmt = vm.filterDefaults;
        }
        restoreFilters($sessionStorage.filters.reclMgmt);
        vm.searchVisible = true;

        vm.statusOptions = ReclamationConstants.MgmtStatusOptions;
        vm.itemsPerPageOptions = paginationConstants.itemsPerPageOptions;
        vm.disabledStatus = ['DRAFT', 'SUSPENDED']; // разрешено редактировать НЕКОТОРЫЕ поля в статусе CLOSED

        vm.changeItemsPerPage = changeItemsPerPage;
        vm.openCalendar = openCalendar;
        vm.isEditDisabled = isEditDisabled;
        vm.getRowColor = getRowColor;
        vm.exportToExcel = exportToExcel;
        vm.toggleStatusFilter = toggleStatusFilter;
        vm.getStatusKey = getStatusKey;
        vm.clearFilter = clearFilter;

        init();

        function restoreFilters (savedFilters) {
            vm.searchParams = savedFilters;
            vm.searchParams.startDateFrom = savedFilters.startDateFrom ? new Date(vm.searchParams.startDateFrom) : null;
            vm.searchParams.startDateTo = savedFilters.startDateTo ? new Date(vm.searchParams.startDateTo) : null;
        }

        function clearFilter () {
            vm.searchParams = vm.filterDefaults;
            $sessionStorage.filters.reclMgmt = vm.filterDefaults;
        }

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.clientName = client.name;
                vm.loadAll();
            });
        }

        function loadAll () {
            Reclamation.find({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, vm.searchParams, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.reclamations = data;
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage () {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function changeItemsPerPage () {
            // 2-way binding doesn't seem to work here for some reason
            $scope.$storage.itemsPerPage.reclamations = vm.itemsPerPage;
            loadAll();
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function isEditDisabled (reclamation) {
            return (reclamation.subStatus === 'CLIENT') || vm.disabledStatus.indexOf(reclamation.status) !== -1;
        }

        vm.inProgressStatus = ['IN_PROGRESS', 'FEEDBACK_REQUIRED', 'FEEDBACK_PROVIDED'];
        function getRowColor (reclamation) {
            if (vm.inProgressStatus.indexOf(reclamation.status) !== -1) {
                if (reclamation.daysLeft < 1) {
                    return "danger";
                } else if (reclamation.daysLeft < 2) {
                    return "warning";
                } else if (reclamation.daysLeft >= 2 && reclamation.daysLeft <= 3) {
                    return "success";
                }
            }
        }

        function exportToExcel () {
            vm.savingXls = true;
            Reclamation.toExcel({
                sort: sort()
            },
            vm.searchParams,
            function (data) {
                vm.savingXls = false;
                FileSaver.saveAs(data.blob, "reclamation_list.xlsx");
            }, function (error) {
                vm.savingXls = false;
                onError(error);
            });
        }

        function toggleStatusFilter (status) {
            if (status) {
                vm.searchParams.statuses = [status];
            } else {
                vm.searchParams.statuses = [];
            }
        }

        function getStatusKey(status, subStatus) {
            return ReclamationUtils.getStatusLabelKey(status, subStatus);
        }
    }
})();