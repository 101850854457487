(function() {
'use strict';

    angular
        .module('rbllkApp')
        .service('Reports', Reports);

    Reports.$inject = ['$resource', 'Blob'];

    function Reports($resource, Blob) {
        var service = $resource('api/reports/:client/:filename', {}, {
            'findInventory': { method: 'POST', url: 'api/report/:client/inventory', isArray: true },
            'exportInventoryToCsv': {
                method: 'POST',
                url: 'api/report/:client/inventory/csv',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'exportInventoryToExcel': {
                method: 'POST',
                url: 'api/excel/rep_inventory/:client',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'findMovements': { method: 'POST', url: 'api/report/:client/movements', isArray: true },
            'exportMovementsToCsv': {
                method: 'POST',
                url: 'api/report/:client/movements/csv',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'exportMovementsToExcel': {
                method: 'POST',
                url: 'api/excel/rep_movement/:client',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'findShipping': { method: 'POST', url: 'api/report/:client/shipping', isArray: true },
            'exportShippingToCsv': {
                method: 'POST',
                url: 'api/report/:client/shipping/csv',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'exportShippingToExcel': {
                method: 'POST',
                url: 'api/excel/rep_shipping/:client',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'findReceiving': { method: 'POST', url: 'api/report/:client/receiving', isArray: true },
            'exportReceivingToCsv': {
                method: 'POST',
                url: 'api/report/:client/receiving/csv',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'exportReceivingToExcel': {
                method: 'POST',
                url: 'api/excel/rep_receiving/:client',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'getInventory': { method: 'GET', url: 'api/report/:client/inventory-alt', isArray: true },
            'exportInventoryToExcelAlt': {
                method: 'POST',
                url: 'api/excel/rep_inventory_alt/:client',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'getReceiving': { method: 'POST', url: 'api/report/:client/receiving-alt', isArray: true },
            'exportReceivingToExcelAlt': {
                method: 'POST',
                url: 'api/excel/rep_receiving_alt/:client',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'getShipping': { method: 'POST', url: 'api/report/:client/shipping-alt', isArray: true },
            'exportShippingToExcelAlt': {
                method: 'POST',
                url: 'api/excel/rep_shipping_alt/:client',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'getMovements': { method: 'POST', url: 'api/report/:client/movements-alt', isArray: true },
            'exportMovementsToExcelAlt': {
                method: 'POST',
                url: 'api/excel/rep_movements_alt/:client',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'getSoStatus': { method: 'POST', url: 'api/report/:client/so-status', isArray: true },
            'exportSoStatusToExcel': {
                method: 'POST',
                url: 'api/excel/rep_so_status/:client',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'getSoLpSelected': { method: 'POST', url: 'api/report/:client/so-lp', isArray: true },
            'exportSoLpSelectedToExcel': {
                method: 'POST',
                url: 'api/excel/rep_so_lp/:client',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            }
        });

        return service;
    }
})();